.hero-img {
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    height: 80vh;
    position: relative;
}

.hero-img::before {
    content: "";
    background-image: url("https://images.unsplash.com/photo-1529346378633-c2b2a059c367?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80");
    background-size: cover;
    background-position: center top;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.60;
}

.heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-img h1 {
    font-size: 1.4rem;
    text-align: center;
}

@media screen and (max-widht: 640px) {
    .hero-img h1 {
        font-size: 2rem;
    }
}