.mask{
    width: 100%;
    height: 100vh;
    position: relative;
}
.intro-img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.mask::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.76;
}

.hero {
    height: 100%;
    width: 100%;
}

.hero .content {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
}

.hero .content h1 {
    font-size: 1.4rem;
    padding: 0.5rem 0 5rem;
    font-weight: 200;
}

.hero .content p {
    font-size: 4rem;
    font-weight: 500;
}

.content .btn {
    margin: 1.2rem 0.2rem;
}

@media screen and (max-width:640px) {
    .hero .content h1 {
        font-size: 1.4rem;
        
    }
    
    .hero .content p {
        font-size: 3.5rem;
        
    }
}