@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Outfit', sans-serif;
}

body {
  background: #000;
}

h1, h4, p,
a{
  color: #fff;
  text-decoration: none;
  
}

ul{
  list-style-type: none;
}

.btn {
  padding: 12px 32px;
  border-radius: 1.2rem;
  font-size: 1rem;
  text-transform: uppercase;
  background: rgb(16, 184, 217);
  color: #222;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.425s;
}

.btn-light {
  /* background: rgba(255, 255, 255, 0.2); */
  background: transparent;
  color: #fff; 
  
}

.btn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  
  
}

